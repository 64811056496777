<template>
  <Content>
    <template #contentTitle>{{ $t("menu.print_waybill_details") }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
         <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.status")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">
            <span v-if="pageInfo.waybillOperationStatus">{{
            $t(
              $enumLangkey(
                "waybillOperationStatus",
                pageInfo.waybillOperationStatus
              )
            )
          }}</span>
          <span v-else>-</span>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.customer_number")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">{{
            pageInfo.customerNo
          }}</a-col>
        </a-row>
         <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.system_number")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">{{
            pageInfo.planNo
          }}</a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.originate")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.from">
            {{
           pageInfo.from.name
          }}
          </a-col>
        </a-row>
         <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.from_product_address")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.from">
            <span>{{ getAddressLanguageName(pageInfo.from) }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.destination_type")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">{{
            $t($enumLangkey("destinationType", pageInfo?.destinationType))
          }}</a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2" v-if="pageInfo?.destinationType!=destinationTypeEnum.OtherAddress">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.warehouse_no")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">{{
            pageInfo.to.no
          }}</a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("logistics.recipients")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.to">
            {{ pageInfo.to?.name }}
            <!-- <a-space class="ml-5">
              <span>{{ $t("warehouse.receive_goods_address") }}:</span>
              <span>{{ getAddressLanguageName(pageInfo.to) }}</span>
              <a-tag color="warning"
                     v-if="pageInfo?.transportRoutes==transportRoutesEnum.international"> {{$t('warehouse.international_transport')}}</a-tag>
              <a-tag color="warning"
                     v-else> {{$t('logistics.local_transport')}}</a-tag>

              <template v-if="channelTable.length">
                <span v-if="channelTable[0].logisticsBaseInfo?.isCommercial !== null">
                  <a-tag color="#f59b22" v-if="channelTable[0].logisticsBaseInfo?.isCommercial">{{ $t("logistics.business_site") }}</a-tag>
                  <a-tag color="#2db7f5" v-else>{{ $t("logistics.residence") }}</a-tag>
                </span>
              </template>
            </a-space> -->
          </a-col>
        </a-row>
        <template v-if="pageInfo?.destinationType === destinationTypeEnum.OtherAddress">
          <a-row :gutter="16" class="mt-2" v-if="pageInfo.to?.companyName">
            <a-col flex="0 1 150px" style="text-align: right">{{ $t("warehouse.recipient_company") }}</a-col>
            <a-col flex="1 1 300px" v-if="pageInfo">{{ pageInfo.to?.companyName }}</a-col>
          </a-row>
          <a-row :gutter="16" class="mt-2" v-if="pageInfo.to?.telephone">
            <a-col flex="0 1 150px" style="text-align: right">{{ $t("warehouse.phone") }}</a-col>
            <a-col flex="1 1 300px" v-if="pageInfo">{{ pageInfo.to?.telephone }}</a-col>
          </a-row>
        </template>

          <a-row :gutter="16" class="mt-2">
            <a-col flex="0 1 150px" style="text-align: right">{{
              $t("warehouse.receive_goods_address")
            }}</a-col>
            <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.to">
                <span>{{ getAddressLanguageName(pageInfo.to) }}</span>
                <a-tag color="warning"
                      v-if="pageInfo?.transportRoutes==transportRoutesEnum.international" class="ml-1"> {{$t('warehouse.international_transport')}}</a-tag>
                <a-tag color="warning"
                      class="ml-1" v-else> {{$t('logistics.local_transport')}}</a-tag>

                <template v-if="channelTable.length">
                  <span v-if="channelTable[0].logisticsBaseInfo?.isCommercial !== null" class="ml-1">
                    <a-tag color="#f59b22" v-if="channelTable[0].logisticsBaseInfo?.isCommercial">{{ $t("logistics.business_site") }}</a-tag>
                    <a-tag color="#2db7f5" v-else>{{ $t("logistics.residence") }}</a-tag>
                  </span>
                </template>
            </a-col>
          </a-row>

           <a-row :gutter="16" class="mt-2" >
            <a-col flex="0 1 150px" style="text-align: right">{{ $t("warehouse.creation_time") }}</a-col>
            <a-col flex="1 1 300px" v-if="pageInfo">
               {{ $filters.utcToCurrentTime(pageInfo.creationTime) }}
              </a-col>
          </a-row>
           <a-row :gutter="16" class="mt-2" >
            <a-col flex="0 1 150px" style="text-align: right">{{ $t("warehouse.tracking_number_1") }}</a-col>
            <a-col flex="1 1 300px" v-if="pageInfo">{{ pageInfo.transferOrderNo??"-" }}</a-col>
          </a-row>
          <a-row :gutter="16" class="mt-2" v-if="pageInfo?.track?.trackRemark">
            <a-col flex="0 1 150px" style="text-align: right">{{ $t("warehouse.turn_single_track") }}</a-col>
            <a-col flex="1 1 300px" >
              <a-row :gutter="8">
                <a-col><small style="color: #aaa;">{{ $filters.utcToCurrentTime(pageInfo?.track?.trackTime) }}</small></a-col>
                <a-col><small style="color: #aaa;">{{ pageInfo?.track?.trackRemark }}</small></a-col>
              </a-row>
            </a-col>
          </a-row>
        <a-table
          class="mt-2"
          :columns="columns"
          size="small"
          :scroll="{ x: true }"
          :data-source="packingInformation"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #title>{{ $t("warehouse.encasement_info") }}</template>
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #weight="{ record }">
            {{ gToKg(record.weight) }}kg
          </template>
          <template #size="{ record }">
            {{ record.length }}x{{ record.width }}x{{ record.height }}cm
          </template>
          <template #girth="{ record }"> {{ record.girth }}cm </template>
          <template #volume="{ record }">
            {{ cmCubicToM(record.volume) }}m<sup>3</sup>
          </template>
          <template #footer>
            <a-row :gutter="48" type="flex" justify="end">
              <a-col v-if="pageInfo"
                >{{ $t("warehouse.total_weight") }}:
                {{ gToKg(pageInfo.totalWeight) }}kg</a-col
              >
              <a-col v-if="pageInfo"
                >{{ $t("warehouse.total_volume") }}:
                {{ cmCubicToM(pageInfo.totalVolume) }}m<sup>3</sup></a-col
              >
            </a-row>
          </template>
        </a-table>
        <div class="mt-3">
          {{ $t("warehouse.transport_characteristics") }}:
          <a-space v-if="pageInfo">
            <span
              v-for="(item, index) in pageInfo.tranPlanCharacteristics"
              :key="index"
              >{{
                $t($enumLangkey("transportCharacteristics", item))
              }}</span
            >
          </a-space>
        </div>
        <a-table
          class="mt-3"
          :columns="secondColumns"
          size="small"
          :data-source="channelTable"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #logisticsCode="{ record }">
            {{ record.logisticsBaseInfo?.code }}
          </template>
          <template #logisticsName="{ record }">
            <span style="color: orange; font-weight: bold">{{
              record.logisticsBaseInfo?.name
            }}</span>
          </template>
          <template #logisticsType="{ record }">
            {{ $t($enumLangkey("logisticsWay", record.logisticsBaseInfo?.logisticsType)) }}
          </template>
          <template #deliveryType="{ record }">
            {{ $t($enumLangkey("deliveryWay", record.logisticsBaseInfo?.deliveryType)) }}
          </template>
          <template #referenceAging="{ record }">
            {{ record.logisticsBaseInfo?.daysMin }}-{{
              record.logisticsBaseInfo?.daysMax
            }}{{ $t("warehouse.workday") }}({{ $t("enum.aging_0") }})
          </template>
          <template #freights="{ record }">
            <div style="color: orange">
              {{ $t("warehouse.average_unit_price") }}:
              {{ record.logisticsBaseInfo?.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo?.averagePrice) }}
            </div>
            <div style="color: orange">
              {{ $t("warehouse.total_prices") }}:
              {{ record.logisticsBaseInfo?.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo?.totalFeeWithoutServices) }}
            </div>
          </template>
          <template #details="{ record }">
            <div>
              {{ $t("logistics.chargeable_weight") }}:
              {{ gToKg(record.logisticsBaseInfo?.chargeableWeight) }}kg
            </div>
            <div>
              {{ $t("logistics.volume_weight_coefficient") }}:
              {{ record.logisticsBaseInfo?.volumeWeightParam }}
            </div>
          </template>
          <template #canChooseServerInfoes="{ record }">
            <template v-if="record.logisticsRegionInfo?.isChooseDdp || record.logisticsRegionInfo?.isChooseSign">
              <div v-if="record.logisticsRegionInfo?.isChooseDdp">
                {{
                    $t(
                      $enumLangkey(
                        "orderLogisticsFee",
                        record.logisticsRegionInfo.ddpFeeType
                      )
                    )
                  }}: {{ record.logisticsBaseInfo.currencySymbol
                  }}{{ $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee) }}
              </div>
              <CSignSupported
                v-if="record.logisticsRegionInfo.isChooseSign"
                v-model:value="record.logisticsRegionInfo.signatureType"
                type="readonly"
                :symbol="record.logisticsBaseInfo.currencySymbol"
                :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
              />
            </template>
            <span v-else>-</span>
          </template>
        </a-table>
        <div v-if="channelTable?.length>0" class="mt-3">
        <span v-if="channelTable[0]?.logisticsRegionInfo?.isVatRequired">
          VAT: {{channelTable[0]?.logisticsRegionInfo?.vatCode}}
        </span>
        </div>

        <CFee v-if="pageInfo" class="mt-5" :fees="pageInfo.warehouseFrees" :totalFee="getTotalFee(pageInfo.warehouseFrees)" />
      </a-spin>
      <a-row type="flex" justify="end" class="mt-3 mr-5">
        <a-col>
          <a-button @click="handleClose">{{ $t("common.close") }}</a-button>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import {
  Spin,
  Space,
  Table,
  Row,
  Col,
  Divider,
  Button,
  Tag,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CFee from "@/views/components/CFee.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useTab } from "@/hooks/tabs/index";
import { getById } from "@/api/modules/printWaybill/list";
import { gToKg, cmCubicToM, getAddressByLanguageV2 } from "@/utils/general";
import {
  destinationType as destinationTypeEnum,
  transportRoutes as transportRoutesEnum
} from "../../enum/enum.json";
import BigNumber from 'bignumber.js';

export default defineComponent({
  name: "print_waybill_details",
  components: {
    ASpin: Spin,
    ASpace: Space,
    ATable: Table,
    ARow: Row,
    ACol: Col,
    ADivider: Divider,
    AButton: Button,
    ATag: Tag,
    Content,
    CFee,
    CSignSupported,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const state = reactive({
      loading: true,
      pageInfo: null,
      packingInformation: [],
      channelTable: [],
    });

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 80,
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        title: () => vueI18n.t("warehouse.actual_weight"),
        width: 150,
        slots: {
          customRender: "weight",
        },
      },
      {
        title: () => vueI18n.t("logistics.size"),
        width: 150,
        slots: {
          customRender: "size",
        },
      },
      {
        title: () => vueI18n.t("warehouse.girth"),
        width: 150,
        slots: {
          customRender: "girth",
        },
      },
      {
        title: () => vueI18n.t("warehouse.volume"),
        width: 150,
        slots: {
          customRender: "volume",
        },
      },
    ];

    const secondColumns = [
      {
        title: () => vueI18n.t("warehouse.channel_code"),
        width: 100,
        slots: {
          customRender: "logisticsCode",
        },
      },
      {
        title: () => vueI18n.t("warehouse.channel_name"),
        width: 150,
        slots: {
          customRender: "logisticsName",
        },
      },
      {
        title: () => vueI18n.t("warehouse.logistics_type"),
        width: 100,
        slots: {
          customRender: "logisticsType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.delivery_way"),
        width: 100,
        slots: {
          customRender: "deliveryType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.reference_aging"),
        width: 120,
        slots: {
          customRender: "referenceAging",
        },
      },
      {
        title: () => vueI18n.t("warehouse.freight"),
        width: 120,
        slots: {
          customRender: "freights",
        },
      },
      {
        title: () => vueI18n.t("common.details"),
        width: 120,
        slots: {
          customRender: "details",
        },
      },
      {
        title: () => vueI18n.t("logistics.selected_service"),
        width: 120,
        slots: {
          customRender: "canChooseServerInfoes",
        },
      },
    ];

    const handleClose = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "print_waybill_list" });
    };

    const getLanguageName = (cnName = "", enName = "") => {
      if (getters.language === "zh-CN") {
        return cnName;
      }
      return enName;
    };

    const getAddressLanguageName = (data) => {
      if (typeof data !== "object") {
        return "";
      }
      return getAddressByLanguageV2(data, getters.language);
    };

    const getTotalFee = (list = []) => {
      let symbol = list.length > 0 ? list[0].symbol : '';
      let value = list.reduce((total, item) => {
        return total.plus(item.value);
      }, new BigNumber(0)).toNumber();
      return { symbol, value, checkValue: null };
    };

    onMounted(() => {
      let id = route.params.id;
      if (id) {
        state.loading = true;
        getById({ id })
          .then(({ result }) => {
            if (result) {
              let { details = [], supplierLogisticScheme, ...info } = result;
              state.packingInformation = details;
              state.pageInfo = info;
              if (supplierLogisticScheme) {
                state.channelTable = [supplierLogisticScheme]
              }
            } else {
              message.error(vueI18n.t("common.exception"));
            }
          })
          .finally(() => {
            state.loading = false;
          });
      } else {
        message.error(vueI18n.t("common.exception"));
      }
    });

    return {
      ...toRefs(state),
      columns,
      secondColumns,
      labelCol: { style: { width: "150px" } },
      wrapperCol: { style: { width: "calc(100% - 150px)" } },
      getLanguageName,
      gToKg,
      cmCubicToM,
      getAddressLanguageName,
      getTotalFee,
      handleClose,
      destinationTypeEnum,
      transportRoutesEnum
    };
  },
});
</script>
